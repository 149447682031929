import React from 'react'

type Props = {
  heading: string
  level: string
  showAs?: string
  mute?: boolean
  anchorId?: string
}

export const HeadingBlock = (props: Props) => {
  const { heading, level, showAs, mute, anchorId } = props

  const showAsClasses = !!showAs && level !== showAs ? showAs : ''
  const muteClasses = mute ? 'u-color-mute-2' : ''
  const classes = `${showAsClasses} ${muteClasses}`

  switch (level) {
    case 'h1':
      return (
        <h1 className={classes} id={anchorId} style={{ scrollMarginTop: '180px' }}>
          {heading}
        </h1>
      )
    case 'h2':
      return (
        <h2 className={classes} id={anchorId} style={{ scrollMarginTop: '180px' }}>
          {heading}
        </h2>
      )
    case 'h3':
      return (
        <h3 className={classes} id={anchorId} style={{ scrollMarginTop: '180px' }}>
          {heading}
        </h3>
      )
    case 'h4':
      return (
        <h4 className={classes} id={anchorId} style={{ scrollMarginTop: '180px' }}>
          {heading}
        </h4>
      )
    case 'h5':
      return (
        <h5 className={classes} id={anchorId} style={{ scrollMarginTop: '180px' }}>
          {heading}
        </h5>
      )
    case 'h6':
      return (
        <h6 className={classes} id={anchorId} style={{ scrollMarginTop: '180px' }}>
          {heading}
        </h6>
      )
  }
  return <></>
}
